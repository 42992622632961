import React, {useEffect} from "react";
import {graphql, navigate, useStaticQuery} from "gatsby";

import {useLang} from "../components/general/lang-manager/LangManager";

import {LoadingBackground, StyledCircular} from "../components/styled/general/loading/loadingComponents";
import Seo from "../components/general/seo/SEO";

const Index = () => {

    const data = useStaticQuery(graphql`
    {
      strapiIndexPages(lang: {eq: "hu"}) {
        SEO {
          title
          keywords
          isIndexable
          description
          preview {
            url
          }
        }
      }
    }
  `)

    const {SEO} = data.strapiIndexPages

    const {langTokens} = useLang()

    useEffect(() => {
        if (langTokens) {
            setTimeout(() => {
                navigate(`/${langTokens}/`);
            }, 1000);
        }
    }, [langTokens]);

    return (
        <>
            <Seo
                description={SEO.description}
                lang={"hu"}
                title={SEO.title}
                image={SEO.preview.url}
                isIndexable={SEO.isIndexable}
                keywords={SEO.keywords}
            />
            <LoadingBackground>
                <StyledCircular size={80}/>
            </LoadingBackground>
        </>
    )
}

export default Index
