import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const LoadingBackground = styled.div`
  background: var(--bg-linear-gradient) !important;
  height: 100vh;
  width: 100vw;
  margin: auto;
  display: grid;
  z-index: 10000;
  position: fixed;
`

export const StyledCircular = styled(CircularProgress)`
  place-self: center;
`